<template>
  <div class="object-service flex">
    <div
      class="object-service__table-wrapper flex flex-col gap-4 pt-5 pb-2 w-7/12"
    >
      <div class="flex justify-between items-start px-5">
        <span class="text-base">{{
          $t('new_units.objects.fields.service.title')
        }}</span>
        <UnitsAddButton :active-tab="activeTab" @click.native="addService" />
      </div>
      <UnitsSearch class="px-5" @onSearch="handleSearch" />
      <UnitsTable
        :table-data="tableData.list"
        :titles="tableData.titles"
        :options="tableData.options"
        :empty="emptyData"
        :key="renderTableKey"
        class="object-service__table"
        @onRowClick="rowClick"
        @onDelete="deleteService"
        @onSort="handleSort"
      />
      <UnitsDeletedRecovery
        v-if="serviceTable.options.recovered.length"
        :count="serviceTable.options.recovered.length"
        :active-tab="activeTab"
        @onCancel="cancelDeletion"
        @onDeleteRecovered="finalDeletion"
      />
    </div>
    <ServiceDetails
      v-if="isNewService || selectedService"
      :service="selectedService"
      class="w-5/12"
      @onIntervalChange="intervalChange"
    />
  </div>
</template>
<script setup>
import { ref, onBeforeMount, watch, computed, getCurrentInstance } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import {
  validateSection,
  sortHelper,
  searchHelper
} from '@/components/unitsNew/helpers/index.js'
import { createVuexHelpers } from 'vue2-helpers'

import ServiceDetails from './ServiceDetails.vue'
import UnitsTable from '@/components/unitsNew/components/UnitsTable.vue'
import UnitsSearch from '@/components/unitsNew/components/UnitsSearch.vue'
import UnitsAddButton from '@/components/unitsNew/components/UnitsAddButton.vue'
import UnitsDeletedRecovery from '@/components/unitsNew/components/UnitsDeletedRecovery.vue'

const $t = useI18n()
const instance = getCurrentInstance()

const renderTableKey = ref(0)

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const { useGetters, useMutations } = createVuexHelpers()

const { activeObject, modalType } = useGetters('units', [
  'activeObject',
  'modalType'
])
const { SET_SERVICES_ACTIVE_OBJECT, SET_SERVICES_SEARCHED } = useMutations(
  'units',
  ['SET_SERVICES_ACTIVE_OBJECT', 'SET_SERVICES_SEARCHED']
)

const serviceTable = ref({
  list: [],
  titles: [
    {
      label: $t('new_units.objects.fields.service.table_columns.service'),
      prop: 'name',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      orderSort: '',
      sortType: '',
      class: ['max-w-textMiddle']
    },
    {
      label: $t('new_units.objects.fields.service.table_columns.mileage'),
      prop: 'second_column',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      class: []
    },
    {
      label: $t('new_units.objects.fields.service.table_columns.days'),
      prop: 'intervalDays',
      width: '30',
      headerAlign: 'center',
      align: 'center',
      icon: true,
      orderSort: '',
      sortType: 'number',
      class: []
    },
    {
      label: '',
      prop: 'delete',
      width: '10',
      headerAlign: 'right',
      align: 'right'
    }
  ],
  options: {
    hasCheckboxColumn: false,
    recovered: []
  }
})

const tableData = computed(() => {
  if (!isSorted.value) return

  isSorted.value = !isSorted.value
  return serviceTable.value
})

const activeTab = 'service'
const isNewService = ref(false)
const selectedService = ref(null)
const isSorted = ref(true)

const deleteTime = 10000
let myTimeout = null

const emptyData = {
  icon: activeTab,
  text: $t('new_units.objects.fields.service.no_service')
}

const handleSearch = (value) => {
  const searched = props.data.services.filter((service) =>
    searchHelper.search(service.name, value)
  )

  SET_SERVICES_SEARCHED(searched)

  serviceTable.value.list = searched
}

const handleSort = (idx) => {
  const orderSort = serviceTable.value.titles[idx].orderSort
  const prop = serviceTable.value.titles[idx].prop
  const sortType = serviceTable.value.titles[idx].sortType

  if (orderSort === 'asc') {
    serviceTable.value.titles[idx].orderSort = 'desc'

    serviceTable.value.list.sort((a, b) =>
      sortHelper.asc(a[prop], b[prop], sortType)
    )
  } else if (orderSort === 'desc') {
    serviceTable.value.titles[idx].orderSort = ''

    generateList()
  } else {
    serviceTable.value.titles[idx].orderSort = 'asc'

    serviceTable.value.list.sort((a, b) =>
      sortHelper.desc(a[prop], b[prop], sortType)
    )
  }

  isSorted.value = true
  renderTableKey.value++
}

const addService = () => {
  const ref = validateSection(activeTab, (valid) => {
    if (!valid)
      return instance.proxy.$showError(
        $t('new_units.objects.fields.service.errors.validation')
      )

    addServiceHandler()
  })

  if (!ref) addServiceHandler()
}

const addServiceHandler = () => {
  isNewService.value = true

  const newService = {
    id: Math.random(),
    description: '',
    intervalDays: '',
    intervalKm: '',
    intervalMotohours: '',
    isActiveDays: false,
    isActiveKm: true,
    isActiveMotohours: false,
    lastDays: '',
    lastKm: '',
    lastMotohours: '',
    name: $t('new_units.unspecified'),
    second_column: '',
    isNew: true
  }

  serviceTable.value.list.push(newService)

  selectedService.value = newService

  SET_SERVICES_ACTIVE_OBJECT(serviceTable.value.list)
}

const finalDeletion = () => {
  if (!serviceTable.value.options.recovered.length) return

  isNewService.value = false

  serviceTable.value.list = serviceTable.value.list.filter(
    (item) => !serviceTable.value.options.recovered.includes(item.id)
  )
  const list = serviceTable.value.list

  if (list.length) {
    selectedService.value = list[list.length - 1]
  } else {
    selectedService.value = null
  }

  serviceTable.value.options.recovered = []

  clearTimeout(myTimeout)

  SET_SERVICES_ACTIVE_OBJECT(serviceTable.value.list)
}

const deletionTimeout = () => {
  myTimeout = setTimeout(finalDeletion, deleteTime)
}

const deleteService = (service) => {
  if (serviceTable.value.options.recovered.includes(service.item.id))
    return instance.proxy.$showError(
      $t('new_units.objects.fields.service.errors.already_deleted')
    )

  serviceTable.value.options.recovered.push(service.item.id)

  clearTimeout(myTimeout)
  deletionTimeout()
}

const cancelDeletion = () => {
  serviceTable.value.options.recovered.splice(0)

  clearTimeout(myTimeout)
}

const rowClick = (service) => {
  validateSection(activeTab, (valid) => {
    if (valid) {
      selectedService.value = service
    } else {
      return instance.proxy.$showError(
        $t('new_units.objects.fields.service.errors.validation')
      )
    }
  })
}

const generateList = () => {
  serviceTable.value.list = props.data.services.slice() || []

  serviceTable.value.list.forEach((item) => {
    item.second_column = ' / '

    if (item.intervalKm)
      item.second_column = item.intervalKm + item.second_column
    if (item.intervalMotohours) item.second_column += item.intervalMotohours

    item.second_column = item.second_column === ' / ' ? '' : item.second_column
  })

  if (serviceTable.value.list.length)
    selectedService.value = serviceTable.value.list[0]

  return serviceTable.value.list
}

const intervalChange = () => {
  renderTableKey.value++
}

watch(activeObject, () => {
  generateList()
})

onBeforeMount(() => {
  if (modalType.value === 'edit') {
    generateList()
  }
})
</script>
<style lang="scss">
@import '@/components/unitsNew/assets/styles/Objects/Service.scss';
</style>
